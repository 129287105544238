import React, { useEffect, useState } from 'react';
import { Segment, Icon, Header, Image, Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { HtmlEditor } from 'devextreme-react/ui/html-editor';
import McqsQuestionTemplate from '../Templates/McqsQuestionTemplate';
import TextQuestionTemplate from '../Templates/TextQuestionTemplate';
import McqsQuestionMockTemplate from '../Templates/McqsQuestionMockTemplate';
import TBSAttachmentModal from '../TBSAttachmentModal';
import CompositeTemplate from '../Templates/CompositeTemplate';
import { Calculator, ErrorSegment, FlexWindow, ReportForm, Separator, VideoPlayer } from '../../..';
import { Consts, Styles, Theme } from '../../../../res';
import iconImage from '../../../../res/images/logo.svg';
import '../../../../res/css/cpa.css';
import { replaceFontFamily } from '../../../../helpers/general';

const { noExtraSpaces, f_40_700_text, f_12_400_text, f_16_700_text } = Styles;

const QuestionPage = (props) => {
    const { Q, Qindex, FactIndex, onAnswerQuestion, loading, error, isMockExam, isViewOnly, user, questions, onSelectQuestion, onFlagQuestion, onFavQuestion, course, onHideSomeAnswers, onExcludeOption } = props;
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const [showReportIssue, setShowReportIssue] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const [showCalculator, setShowCalculator] = useState(false);
    const [isSubmitClicked, setIsSubmitClicked] = useState(Q?.isSubmited);
    const [modalStates, setModalStates] = useState([]);

    const handleOpenModal = (index) => {
        const updatedModalStates = [...modalStates];
        updatedModalStates[index] = true;
        setModalStates(updatedModalStates);
    };

    const handleCloseModal = (index) => {
        const updatedModalStates = [...modalStates];
        updatedModalStates[index] = false;
        setModalStates(updatedModalStates);
    };

    useEffect(() => setIsSubmitClicked(Q?.isSubmited), [Q]);

    if (loading || !Q) return <Segment basic style={{ height: '40vh', ...noExtraSpaces }} loading={loading} />;
    if (error) return <ErrorSegment />;

    const renderMcqsTemplate = () => {
        if (Q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS) {
            if (isMockExam)
                return <McqsQuestionMockTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isViewOnly={isViewOnly} onExcludeOption={onExcludeOption} />;
            else return <McqsQuestionTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isViewOnly={isViewOnly} onHideSomeAnswers={onHideSomeAnswers} onExcludeOption={onExcludeOption} />;
        }
        return null;
    };

    return (
        <Segment basic style={{ ...noExtraSpaces, backgroundColor: "#ececec", minHeight: '85vh' }}>

            <div style={{ marginLeft: '5px', width: '50vw', position: 'relative', height: "100%" }}>

                <div style={{ padding: 5, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10, justifyContent: 'center' }}>
                    {questions.map((q, index) => {
                        const isSelected = Qindex === index;
                        return <div key={index} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignItems: 'center' }} onClick={() => onSelectQuestion(q)}>
                            <div><Header style={{ ...f_12_400_text, fontWeight: isSelected ? f_16_700_text.fontWeight : f_12_400_text.fontWeight, fontSize: isSelected ? f_16_700_text.fontSize : f_12_400_text.fontSize, color: isSelected ? Theme.colors.CPA_ORANGE : Theme.colors.BLACK }}>{index + 1}</Header></div>
                            {!!q.isFlagged && <Icon size='tiny' name="bookmark" inverted style={{ color: 'maroon' }} />}
                        </div>;
                    })}
                </div>

                <div style={{ backgroundColor: "white", borderLeft: `solid 3px ${Theme.colors.CPA_ORANGE}`, boxShadow: "2px 2px 5px #aaaaaa" }}>
                    <div style={{ padding: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Icon link size='big' style={{ color: '#043a4e' }} disabled={Qindex <= 0} onClick={() => onSelectQuestion(questions[Qindex - 1])} name='caret left' />
                            <div><Header style={{ ...f_40_700_text, color: "maroon" }}>{Q.factId ? FactIndex + 1 : Qindex + 1}</Header></div>
                            <Icon link name={Q.isFlagged ? "bookmark" : "bookmark outline"} inverted style={{ color: 'maroon' }} onClick={() => onFlagQuestion(Q)} />
                            <Icon link name={Q.isFav ? "star" : "star outline"} inverted style={{ color: 'maroon' }} onClick={() => onFavQuestion(Q)} />
                            <Icon link size='big' style={{ color: '#043a4e' }} disabled={Qindex >= questions.length - 1} onClick={() => onSelectQuestion(questions[Qindex + 1])} name='caret right' />
                            <Header style={{ ...f_16_700_text, color: "maroon", marginBottom: 0, marginTop: 0, marginLeft: 'auto' }}>{course.name}</Header>
                            {!isMockExam && <Icon size='large' title={t('ReportProblem')} name='exclamation triangle' color='red' link onClick={() => setShowReportIssue(true)} style={{ marginLeft: 'auto' }} />}
                        </div>

                        {Q.questionText && <div className="widget-container">
                            {Q.factId && <HtmlEditor value={replaceFontFamily(Q.factContent.trim())} readOnly style={{ border: 'none', minHeight: '35vh', height: '35vh' }} className='resizo' />}
                            <Separator h='1vh' />
                            <HtmlEditor value={replaceFontFamily(Q.questionText.trim())} readOnly style={{ border: 'none' }} className='resizo' />
                        </div>}

                        {Q.questionTypeId === Consts.QUESTIONS_TYPES.COMPOSITE &&
                            Q.attachments?.filter(d => d.type !== "CORRECT_ANSWER").length > 0 && <div className='exhibit-management' style={{ border: '1px solid #e6e6e6', backgroundColor: '#fbfbfb', padding: '14px 10px 0', marginBottom: '8px' }}>
                                <div style={{ fontSize: '15px', fontFamily: 'roboto_condensedbold, sans-serif', color: '#043a4e', marginBottom: '25px', display: 'inline-block', position: 'relative', top: '-5px' }}>{t('EXHIBITS')}</div>
                                <div className='exhibit-tools' style={{ float: 'right', width: '150px', marginTop: '-6px', marginRight: '-15px' }}>
                                    <a className="tool-button" title="Cascade Exhibit Windows" aria-label="Cascade Exhibit Windows" disabled="disabled"><span className="cascade"></span></a>
                                    <a className="tool-button" title="Tile Exhibit Windows" disabled="disabled"><span aria-label="Tile Exhibit Windows" className="tile"></span></a>
                                    <a className="tool-button" title="Close all exhibits" aria-label="Close all exhibits" disabled="disabled"><span className="close-all"></span></a>
                                </div>
                                <div className="exhibit-container">
                                    {
                                        Q.attachments?.map((d, i) =>
                                            <span key={d.docId}>
                                                <span className="exhibit-link" onClick={() => handleOpenModal(i)} style={{ display: d.type === "CORRECT_ANSWER" && 'none' }}>
                                                    <a title={isArabic ? d.nameAr : d.nameEn} aria-label={isArabic ? d.nameAr : d.nameEn} >
                                                        <span className="exhibit-icon"></span>{isArabic ? d.nameAr : d.nameEn}
                                                    </a>
                                                </span>
                                            </span>
                                        )
                                    }
                                </div>
                            </div>}

                        {renderMcqsTemplate()}
                        {Q.questionTypeId === Consts.QUESTIONS_TYPES.COMPOSITE && <CompositeTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isViewOnly={isViewOnly} isAnswerd={Q?.answerJson ? true : false} />}
                        {Q.questionTypeId === Consts.QUESTIONS_TYPES.ESSAY && <TextQuestionTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isMockExam={isMockExam} isViewOnly={isViewOnly} />}

                        {(!isMockExam && !isViewOnly && !isSubmitClicked) && <Segment basic clearing>
                            <Button
                                onClick={() => {
                                    setIsSubmitClicked(true);
                                    onAnswerQuestion(Q, true);
                                }}
                                floated='right'
                                style={{ borderRadius: 0, backgroundColor: Theme.colors.CPA_ORANGE, color: Theme.colors.WHITE }}>
                                {t("Submit")}
                            </Button>
                        </Segment>}

                        {((!isMockExam && isSubmitClicked) || isViewOnly) && <Segment basic clearing>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
                                {
                                    Q.attachments?.map((d, i) => <Button
                                        key={d.docId}
                                        onClick={() => handleOpenModal(i)}
                                        style={{ display: d.type !== "CORRECT_ANSWER" && "none", borderRadius: 0, backgroundColor: Theme.colors.CPA_ORANGE, color: Theme.colors.WHITE }}>
                                        {t("Solution")}
                                    </Button>)
                                }

                                <Button
                                    onClick={() => setShowExplanation(true)}
                                    style={{ borderRadius: 0, backgroundColor: Theme.colors.CPA_ORANGE, color: Theme.colors.WHITE }}
                                >
                                    {t('Explanation')}
                                </Button>
                            </div>
                        </Segment>}
                    </div>

                    <Segment basic textAlign='center'>
                        <Icon link size='big' style={{ color: '#043a4e' }} disabled={Qindex <= 0} onClick={() => onSelectQuestion(questions[Qindex - 1])} name='caret left' />
                        <Icon link size='big' style={{ color: '#043a4e' }} onClick={() => setShowCalculator(true)} name='calculator' />
                        <Icon link size='big' style={{ color: '#043a4e' }} disabled={Qindex >= questions.length - 1} onClick={() => onSelectQuestion(questions[Qindex + 1])} name='caret right' />
                    </Segment>

                    <div className='studentExamWatermark' style={{ top: '40%', left: '40%' }}>
                        <Image loading="lazy" size='medium' src={iconImage} />
                    </div>

                    <Header
                        as='h1'
                        content={`${user.firstName} ${user.lastName} [${user.email}] ${user.contactNumber}`}
                        className='studentExamWatermark'
                    />

                </div>
            </div>

            <Modal
                onClose={() => setShowReportIssue(false)}
                open={showReportIssue}
                content={<ReportForm reportOn={Consts.REPORTS_TYPES.QUESTION} reportLink={Q} id={Q.questionId} showModal={setShowReportIssue} />}
                closeIcon
                header={t('ReportProblem')}
                size='small'
                centered
                closeOnDimmerClick={false}
            />

            <Modal
                onClose={() => setShowExplanation(false)}
                open={showExplanation}
                content={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? <VideoPlayer kind={Consts.VIDEOS_KINDS.EXPLANATION} videoDetails={{ ...Q.correctAnswer.explanations.videos[0], ...Q }} onClose={() => setShowExplanation(false)} /> : <ErrorSegment text={t('ComingSoon')} icon='wait' />}
                basic={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? true : false}
                size='large'
                centered
                closeIcon={!(Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length)}
                closeOnDimmerClick={false}
            />

            {showCalculator && <FlexWindow
                isVisible={showCalculator}
                onClose={() => setShowCalculator(false)}
                title={t("Calculator")}
            >
                <Calculator />
            </FlexWindow>}

            {
                Q.attachments?.map((d, i) =>
                    // <div key={d.docId}>
                    <span key={d.docId + 10} >
                        <TBSAttachmentModal
                            index={i}
                            docUrl={d.docUrl}
                            title={isArabic ? d.nameAr : d.nameEn}
                            open={modalStates[i]}
                            closeModalAction={handleCloseModal}
                        />
                    </span>
                )
            }
        </Segment >
    );
};

export default QuestionPage;
import React, { useState, useEffect } from 'react';
import { FileUploader } from 'devextreme-react/ui/file-uploader';
import { Grid, GridColumn, Header, Icon, Image, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Styles, Theme } from '../../res';
import { BASE_URL } from '../../api';

const { f_14_700_text } = Styles;

const ProfileImageUploader = ({ data, Url, isShort, deleteFromServer = true }) => {
    const { t } = useTranslation();
    const uploadUrl = Url ? `${BASE_URL}${Url}` : `${BASE_URL}/img/upload`;
    const [imageSource, setImageSource] = useState(data.value || '');
    const [loading, setLoading] = useState(false);
    const allowedFileExtensions = ['.jpg', '.jpeg', '.png'];

    useEffect(() => {
        let open = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function () {
            open.apply(this, arguments);
            this.withCredentials = true;
        };
    }, []);

    const onDeleteImage = () => {
        setImageSource(null);
        deleteFromServer && data.setValue(0);
    };

    const onUploaded = (e) => {
        const file = e.file;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setImageSource(fileReader.result);
            setLoading(false);
        };
        fileReader.readAsDataURL(file);

        if (data.questionId) {
            const response = JSON.parse(e.request.response);
            data.setValue({ imgsUrls: [response.imgUrl], questionId: data.questionId });
        }
        else data.setValue(JSON.parse(e.request.response).imgUrl);

    };

    if (isShort) {
        return (
            <>
                <FileUploader
                    id="file-uploader"
                    multiple={false}
                    accept="image/x-png,image/jpg,image/jpeg,image/png"
                    allowedFileExtensions={allowedFileExtensions}
                    uploadMode="instantly"
                    uploadUrl={uploadUrl}
                    visible={false}
                    allowCanceling
                    name='img'
                    dialogTrigger="#dropzone-external"
                    dropZone='#dropzone-external'
                    maxFileSize={10000000}
                    onUploaded={onUploaded}
                    onUploadStarted={() => setLoading(true)}
                    onUploadAborted={() => setLoading(false)}
                    onUploadError={() => setLoading(false)}
                />

                {
                    loading ? <Segment basic padded loading />
                        : imageSource ? <Image loading="lazy" src={imageSource} size='tiny' rounded centered />
                            : <Segment basic textAlign='center'>
                                <Icon name='picture' circular size='big' />
                            </Segment>
                }

                {!loading && <Header id="dropzone-external" textAlign='center' style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, cursor: 'pointer' }}>{t('ChangeImage').toUpperCase()}</Header>}
                {!loading && <Header textAlign='center' style={{ ...f_14_700_text, color: Theme.colors.ERROR_50, marginTop: 0, cursor: 'pointer' }} onClick={onDeleteImage}>{t('DeleteImage').toUpperCase()}</Header>}

            </>
        );
    }

    return (
        <div>
            <Grid stackable columns={2}>
                <GridColumn textAlign='center' verticalAlign='middle'>
                    <FileUploader
                        id="file-uploader"
                        multiple={false}
                        accept="image/x-png,image/jpg,image/jpeg,image/png"
                        allowedFileExtensions={allowedFileExtensions}
                        uploadMode="instantly"
                        uploadUrl={uploadUrl}
                        visible
                        allowCanceling
                        name='img'
                        maxFileSize={10000000}
                        onUploaded={onUploaded}
                        onUploadStarted={() => setLoading(true)}
                        onUploadAborted={() => setLoading(false)}
                        onUploadError={() => setLoading(false)}
                    />
                </GridColumn>

                <GridColumn textAlign='center'>
                    {
                        loading ? <Segment size='huge' basic placeholder loading /> :
                            imageSource
                                ? <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: 10, right: 10 }}>
                                        <Icon link name='trash' color='red' circular onClick={onDeleteImage} />
                                    </div>
                                    <Image loading="lazy" src={imageSource} size='tiny' rounded centered />
                                </div>
                                : <Icon name='picture' circular size='large' />
                    }
                </GridColumn>
            </Grid>
        </div>
    );
};



export default ProfileImageUploader;
import { store } from '../store';
const { getState } = store;

export default (resourcesArr) => {
    const user = getState().auth.user;
    const PERMISSIONS = {
        ADD: false,
        UPDATE: false,
        DELETE: false,
        READ: false
    };

    resourcesArr.forEach(requiredResource => {
        const ac = user.ac[requiredResource] || {};
        PERMISSIONS.ADD = ac.ADD || PERMISSIONS.ADD;
        PERMISSIONS.UPDATE = ac.EDIT || PERMISSIONS.UPDATE;
        PERMISSIONS.DELETE = ac.DELETE || PERMISSIONS.DELETE;
        PERMISSIONS.READ = ac.READ || PERMISSIONS.READ;
    });
    return PERMISSIONS;
};
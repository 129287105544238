import React, { useRef, useState } from 'react';
import { Button, Card, Form, FormField, FormGroup, Header, Input, Radio, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import VideoUploader from '../../Uploaders/VideoUploader';
import VideoPlayer from '../../Generals/VideoPlayer';
import checkResources from '../../../helpers/checkResources';
import request from '../../../api';

const VideoBlock = ({ data, saveBlock }) => {
    const VIDEOS_PERMISSIONS = checkResources(['VIDEOS']);
    const { t } = useTranslation();
    const [videoEn, setVideoEn] = useState(data?.videoEn || "");
    const [videoAr, setVideoAr] = useState(data?.videoAr || "");
    const [isLink, setIsLink] = useState(true);
    const linkEnRef = useRef();
    const linkArRef = useRef();

    const addEnVideo = (details) => {
        request.post('/video/new', details);
        setVideoEn(details.urlEn);
        saveBlock(data.blockId, { ...data, videoEn: details.urlEn });
    };

    const addArVideo = (details) => {
        request.post('/video/new', details);
        setVideoAr(details.urlEn);
        saveBlock(data.blockId, { ...data, videoAr: details.urlEn });
    };

    return (
        <Segment>
            <Form>
                <FormGroup inline>
                    <FormField
                        control={Radio}
                        label={t("Link")}
                        value='1'
                        checked={isLink}
                        onChange={() => setIsLink(true)}
                    />
                    <FormField
                        control={Radio}
                        label={t("Upload")}
                        value='1'
                        checked={!isLink}
                        onChange={() => setIsLink(false)}
                    />
                </FormGroup>
            </Form>
            <Header>{t("EnglishVideo")}</Header>
            {videoEn ?
                <Card centered fluid>
                    <Segment basic>
                        <VideoPlayer videoDetails={{ url: videoEn, showReactions: false, showNotes: false, showStats: false }} onClose={() => { }} />
                    </Segment>
                    <Button.Group >
                        {VIDEOS_PERMISSIONS.DELETE && <Button icon='trash' onClick={() => {
                            setVideoEn(null);
                            saveBlock(data.blockId, { ...data, videoEn: null });
                        }} />}
                    </Button.Group>
                </Card>
                : isLink ? <Input action placeholder={t("PasteLink")}>
                    <input ref={linkEnRef} />
                    <Button color='teal' content={t("Save")} onClick={() => {
                        const url = linkEnRef.current.value;
                        setVideoEn(url);
                        saveBlock(data.blockId, { ...data, videoEn: url });
                    }} />
                </Input>
                    : <VideoUploader data={{ value: videoEn, setValue: addEnVideo, isGeneral: true }} />}


            <Header>{t("ArabicVideo")}</Header>
            {videoAr ?
                <Card centered fluid>
                    <Segment basic>
                        <VideoPlayer videoDetails={{ url: videoEn, showReactions: false, showNotes: false, showStats: false }} onClose={() => { }} />
                    </Segment>
                    <Button.Group >
                        {VIDEOS_PERMISSIONS.DELETE && <Button icon='trash' onClick={() => {
                            setVideoAr(null);
                            saveBlock(data.blockId, { ...data, videoAr: null });
                        }} />}
                    </Button.Group>
                </Card>
                : isLink ?
                    <Input action placeholder={t("PasteLink")}>
                        <input ref={linkArRef} />
                        <Button color='teal' content={t("Save")} onClick={() => {
                            const url = linkArRef.current.value;
                            setVideoAr(url);
                            saveBlock(data.blockId, { ...data, videoAr: url });
                        }} />
                    </Input>
                    : <VideoUploader data={{ value: videoAr, setValue: addArVideo, isGeneral: true }} />}
        </Segment>
    );
};

export const VideoBlockViewer = ({ data }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    return (
        <VideoPlayer videoDetails={{ url: isArabic ? (data?.videoAr || data?.videoEn) : data?.videoEn, showReactions: false, showNotes: false, showStats: false, style: { borderRadius: 5 } }} onClose={() => { }} />
    );
};

export default VideoBlock;
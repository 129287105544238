import React, { useState } from 'react';
import { Button, Grid, GridColumn, Modal, Segment } from 'semantic-ui-react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Calculator, ErrorSegment, FlexWindow, VideoPlayer } from '../../..';
import { Consts } from '../../../../res';
import PresentValues from '../../../../res/pdfs/presentvalues.pdf';
import QuestionTranslationModal from '../QuestionTranslationModal';

const ExamFooter = ({ onFlagQuestion, onFavQuestion, Q, Qindex, isMockExam, isViewOnly, summary, onSelectQuestion, questions, additionalStyles, t, onGoingExam }) => {

    const [showExplanation, setShowExplanation] = useState(false);
    const isArabic = i18next.language === 'ar';
    const [showTranslationModal, setShowTranslationModal] = useState(false);
    const [showCalculator, setShowCalculator] = useState(false);

    if (!Q) return null;

    return (
        <Segment inverted basic style={{...additionalStyles}}>
            <Grid stackable columns='3' verticalAlign='middle'>
                <GridColumn>
                    {!summary.show && <Button inverted basic color={Q.isFlagged ? 'red' : 'blue'} icon={Q.isFlagged ? 'times' : 'flag'} labelPosition='left' content={t('Flag')} style={{ textDecoration: Q.isFlagged ? 'line-through' : 'none' }} onClick={() => onFlagQuestion(Q)} />}
                    {!summary.show && <Button inverted basic color='yellow' icon={Q.isFav ? 'star' : 'star outline'} labelPosition='left' content={t('Fav')} onClick={() => onFavQuestion(Q)} />}
                    {!summary.show && !isMockExam && (Q.answerText || Q.answerOptionId || isViewOnly) ? <Button inverted basic color='pink' icon='video play' labelPosition='left' content={t('Explanation')} onClick={() => setShowExplanation(true)} /> : null}
                </GridColumn>

                <GridColumn textAlign='center'>
                    <a title={t('Attachments')} href={PresentValues} rel='noreferrer' target='_blank'>
                        <Button inverted basic icon='attach' />
                    </a>
                    <Button inverted basic icon='calculator' onClick={() => setShowCalculator(!showCalculator)} />
                    <Button inverted basic content={t('ReviewAnswers')} onClick={() => summary.setShowSummary(!summary.show)} />
                    {/* <Button inverted basic icon='translate' content={t('Translate')}  onClick={() => setShowTranslationModal(true)}/> */}
                </GridColumn>

                {!summary.show && <GridColumn textAlign='right'>
                    <Button.Group basic inverted>
                        <Button disabled={Qindex <= 0} onClick={() => onSelectQuestion(questions[Qindex - 1])} labelPosition='left' icon='left chevron' content={t('Back').toUpperCase()} />
                        <Button disabled={Qindex >= questions.length - 1} onClick={() => onSelectQuestion(questions[Qindex + 1])} labelPosition='right' icon='right chevron' content={t('Next').toUpperCase()} />
                    </Button.Group>
                </GridColumn>}
            </Grid>

            <Modal
                onClose={() => setShowExplanation(false)}
                open={showExplanation}
                content={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? <VideoPlayer kind={Consts.VIDEOS_KINDS.EXPLANATION} videoDetails={{ ...Q.correctAnswer.explanations.videos[0], ...Q }} onClose={() => setShowExplanation(false)} /> : <ErrorSegment text={t('ComingSoon')} icon='wait' />}
                basic={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? true : false}
                size='large'
                centered
                closeIcon={!(Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length)}
                closeOnDimmerClick={false}
            />

            {showCalculator && <FlexWindow
                isVisible={showCalculator}
                onClose={() => setShowCalculator(false)}
                title={t("Calculator")}
            >
                <Calculator />
            </FlexWindow>}

            {/* <QuestionTranslationModal show={showTranslationModal} setShow={setShowTranslationModal} 
                question={Q} lang={isArabic ? 'en' : 'ar'} examRoundId={onGoingExam.examRoundId} /> */}

        </Segment >
    );
};

export default withTranslation()(ExamFooter);
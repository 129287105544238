import _ from 'lodash';
import React, { useState } from 'react';
import { Accordion, Button, Checkbox, Divider, Grid, GridColumn, Header, Icon, Menu, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useJsonSectionParser from '../QuestionBuilder/useJsonSectionParser';
import Block from './Blocks';
import { Consts, Theme } from '../../res';
import { swapArrayLocs } from '../../helpers/general';


const BlockEditor = ({ isReadOnly, data: { value, setValue } }) => {
    const { t } = useTranslation();
    const [data, setData] = useState(useJsonSectionParser(value, { blocks: [], settings: {} }));
    const [selectedBlock, setSelectedBlock] = useState(-1);

    const handleSelectedBlock = (e, props) => {
        const { index } = props;
        const newIndex = selectedBlock === index ? -1 : index;
        setSelectedBlock(newIndex);
    };

    const addNewBlock = (blockType) => setData({ ...data, blocks: [...data.blocks, { blockId: `${_.uniqueId('block')}_${new Date().getTime()}`, type: blockType }] });
    const deleteBlock = (blockId) => {
        const temp = { ...data, blocks: data.blocks.filter(s => s.blockId !== blockId) };
        setData(temp);
        setSelectedBlock(-1);
        setValue(JSON.stringify(temp));
    };

    const saveBlock = (blockId, blockData) => {
        const temp = { ...data };
        temp.blocks.splice(data.blocks.findIndex(s => s.blockId === blockId), 1, blockData);
        setData(temp);
        setValue(JSON.stringify(temp));
    };

    const moveBlockUp = (index) => {
        if (index === 0) return;
        const newData = swapArrayLocs(data.blocks, index, index - 1);
        setData({ ...data, blocks: newData });
        setValue(JSON.stringify({ ...data, blocks: newData }));
    };

    const moveBlockDown = (index) => {
        if (index === data.length - 1) return;
        const newData = swapArrayLocs(data.blocks, index, index + 1);
        setData({ ...data, blocks: newData });
        setValue(JSON.stringify({ ...data, blocks: newData }));
    };

    return (
        <Segment>
            <div>
                <Header>{t("ContentSettings")}</Header>
                <Segment basic>
                    <Checkbox label={{ children: t("ShowMainImage") }} checked={data?.settings?.showMainImage} toggle onChange={(_, { checked }) => {
                        const newData = { ...data, settings: { ...data.settings, showMainImage: checked } };
                        setData(newData);
                        setValue(JSON.stringify(newData));
                    }} />
                </Segment>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Header>{t("AddNewBlock")}</Header>
                <div>
                    <Button title={t("TextBlock")} size='small' icon='font' color='green' onClick={() => addNewBlock(Consts.BLOCK_EDITOR_BLOCKS_TYPES.TEXT_BLOCK)} />
                    <Button title={t("VideoBlock")} size='small' icon='video' color='green' onClick={() => addNewBlock(Consts.BLOCK_EDITOR_BLOCKS_TYPES.VIDEO_BLOCK)} />
                    <Button title={t("GalleryBlock")} size='small' icon='images' color='green' onClick={() => addNewBlock(Consts.BLOCK_EDITOR_BLOCKS_TYPES.GALLERY_BLOCK)} />
                </div>
            </div>

            <Divider />

            <Accordion exclusive as={Menu} vertical fluid style={{ backgroundColor: "transparent", border: 'none', boxShadow: 'none' }} >
                {
                    data.blocks.map((block, index) => <Menu.Item as={Segment} key={index} style={{ margin: 5, backgroundColor: Theme.colors.WHITE, border: 'none', boxShadow: 'none' }}>
                        <Accordion.Title
                            active={selectedBlock === index}
                            content={
                                <Grid stackable columns='2' style={{ margin: 0, padding: 0 }}>
                                    <GridColumn mobile='16' computer='10' style={{ padding: 0 }} >
                                        <Header icon={block.type === Consts.BLOCK_EDITOR_BLOCKS_TYPES.TEXT_BLOCK ? "font" : block.type === Consts.BLOCK_EDITOR_BLOCKS_TYPES.VIDEO_BLOCK ? 'video' : 'images'} content={`${t("Section")} #${index + 1}`} />
                                    </GridColumn>
                                    <GridColumn mobile='16' computer='6' style={{ padding: 0 }} textAlign='right'>
                                        <Button title={t("Up")} size='small' icon='arrow up' color='blue' onClick={() => moveBlockUp(index)} />
                                        <Button title={t("Down")} size='small' icon='arrow down' color='blue' onClick={() => moveBlockDown(index)} />
                                        <Button title={t("DeleteBlock")} size='small' icon='trash' color='red' onClick={() => deleteBlock(block.blockId)} />
                                        <Button size='small' basic icon={selectedBlock === index ? "chevron down" : "chevron left"} color='grey' onClick={() => handleSelectedBlock(null, { index })} />
                                    </GridColumn>
                                </Grid>
                            }
                            index={index}
                            icon={<Icon name='plus' size='mini' />}
                        />
                        <Accordion.Content active={selectedBlock === index} content={<Block
                            data={{ ...block, order: index + 1 }}
                            saveBlock={saveBlock}
                            deleteBlock={deleteBlock}
                            // cloneSection={cloneSection}
                            // moveSectionDown={moveSectionDown}
                            // moveSectionUp={moveSectionUp}
                            blockIndex={index}
                            isReadOnly={isReadOnly}
                        />} />
                    </Menu.Item>
                    )
                }
            </Accordion>
        </Segment>
    );
};

export default BlockEditor;

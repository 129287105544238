import React from 'react';

const allowCopyPaste = (e) => {
    e.stopPropagation();
};

const AllowCopyPaste = ({ children }) => {
    return (
        <div unselectable='off' onCopy={allowCopyPaste} onPaste={allowCopyPaste} onDrag={allowCopyPaste} onDrop={allowCopyPaste} onSelect={allowCopyPaste} className='allowCopyPaste' >
            {children}
        </div>
    );
};

export default AllowCopyPaste;
export const Consts = {
    EXAM_TYPES: {
        TEST_ESSAY: 'Test_Knowledge_Essay',
        TEST_MCQS: 'Test_Knowledge_Mcq',
        TEST_TBS: "Test_Knowledge_Composite",
        TEST_FILE: "TEST_KNOWLEDGE_AFILES",
        FILE: "AFILES_BANK",
        TBS: "Composite_Bank",
        ESSAY: 'Essay_bank',
        MCQS: 'MCQ_Bank',
        FREQ_PARENT: 'FREQ_PARENT',
        FREQ: 'FREQ'
    },
    EXAM_THEMES: {
        SOCPA: 'SOCPA',
        CMA: 'CMA',
        CIA: "CIA",
        CPA: "CPA",
        DIFR: "DIFR"
    },
    QUESTIONS_TYPES: {
        TEXT: "TEXT",
        MCQS: 'MCQ',
        ESSAY: 'ESSAY',
        COMPOSITE: "COMPOSITE",
        File: "AFILES"
    },
    QUESTIONS_FORMATS: {
        MATRIX: 'MATRIX',
        MULTIPLE_ANSWERS: 'MULTIPLE_ANSWERS',
        NORMAL: 'NORMAL',
        TRUE_FALSE: 'TRUE_FALSE'
    },
    REPORTS_TYPES: {
        VIDEO: "VIDEO",
        QUESTION: "QUESTION"
    },
    REPORTS_STATUS_TYPES: {
        NEW: "NEW",
        REJECTED: "REJECTED",
        RESOLVED: "RESOLVED"
    },
    VIDEOS_KINDS: {
        TOPIC: "TOPIC",
        EXAM: "EXAM",
        EXPLANATION: "EXPLANATION",
        GENERAL: "GENERAL"
    },
    NOTIFICATIONS_TYPES: {
        NORMAL: "NORMAL",
        IMPORTANT: "IMPORTANT"
    },
    QUESTION_BUILDER_SECTIONS_TYPES: {
        TEXT_SECTION: "TEXT_SECTION",
        SELECTOR_SECTION: "SELECTOR_SECTION",
        PARAGRAPH_SECTION: "PARAGRAPH_SECTION",
        TABLE_SECTION: "TALBE_SECTION",
        CHECKBOXES_SECTION: "CHECKBOXES_SECTION"
    },
    BLOCK_EDITOR_BLOCKS_TYPES: {
        TEXT_BLOCK: "TEXT_BLOCK",
        VIDEO_BLOCK: "VIDEO_BLOCK",
        GALLERY_BLOCK: "GALLERY_BLOCK"
    },
    NEWS_TYPES: {
        ANNOUNCEMENT: 'Announcement',
        POPUP: 'Popup'
    },
    DISCOUNT_TYPES: {
        AMOUNT: 'Amount',
        PERCENT: 'Percent'
    },
    PACKAGES_TAGS: {
        DEMO: "DEMO",
        FREE: "FREE",
        PAID: "PAID"
    }
};
export const preparingShowingFactsForCma = (questions) => {

    const questionsToCopy = [];
    let lastFactId = -1;
    for (let i = 0; i < questions.length; i++) {
        if (questions[i].factId !== lastFactId) {
            lastFactId = questions[i].factId;
            questionsToCopy.push({ indx: i, Q: questions[i] });
        }
    }

    for (let i = 0; i < questionsToCopy.length; i++)
        questions.splice(questionsToCopy[i].indx + i, 0, { ...questionsToCopy[i].Q, showFactOnly: true, questionId: questionsToCopy[i].Q.questionId + 1000, qFactIndex: i });

    let currentFactIndex;
    for (let i = 0; i < questions.length; i++) {
        if (questions[i]?.showFactOnly) currentFactIndex = i;
        else {
            questions[i]['qAterFactIndex'] = i - currentFactIndex;
        }
    }

    return questions;
};
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RequireStudent } from '../middlewares/HOC';
import PATHS from './PATHS';

import {
    LandingCerts, LandingCourses, LandingCertificationDetails, LandingCourseDetails, LandingHonor,
    TermsAndConditions, PrivacyPolicy, UserSupport, UserNotifications, UserCalendar, LandingWhyUs,
    UserDashboard, UserCourseStudy, UserCourseDetails, UserSubsctiptions, UserPerformance,
    UserMcqsBank, UserTbsBank, UserFileBank,
    UserEssaysBank, UserMockExam, UserExam, UserFreqExam, UserExamsLog,
    UserExamCorrection, UserExamResutls, LandingBlogs, LandingBlogDetails
} from '../pages';

const StudentStack = () => {

    return <Switch>

        <Route path={PATHS.LANDING_CERTIFICATIONS.URL} exact component={LandingCerts} />
        <Route path={PATHS.LANDING_COURSES.URL} exact component={LandingCourses} />
        <Route path={PATHS.LANDING_CERTIFICATIONS_DETAILS.URL} exact component={LandingCertificationDetails} />
        <Route path={PATHS.LANDING_COURSE_DETAILS.URL} exact component={LandingCourseDetails} />
        <Route path={PATHS.LANDING_TERMS_AND_CONDITIONS.URL} exact component={TermsAndConditions} />
        <Route path={PATHS.LANDING_PRIVACY_POLICY.URL} exact component={PrivacyPolicy} />
        <Route path={PATHS.LANDING_HONOR.URL} exact component={LandingHonor} />
        <Route path={PATHS.LANDING_WHYUS.URL} exact component={LandingWhyUs} />
        <Route path={PATHS.LANDING_CALENDAR.URL} exact component={UserCalendar} />
        <Route path={PATHS.USER_SUPPORT.URL} exact component={UserSupport} />
        <Route path={PATHS.LANDING_BLOGS.URL} exact component={LandingBlogs} />
        <Route path={PATHS.LANDING_BLOG_DETAILS.URL} exact component={LandingBlogDetails} />



        <Route path={PATHS.USER_DASHBOARD.URL} exact component={RequireStudent(UserDashboard)} />
        <Route path={PATHS.USER_SUBSCRIPTIONS.URL} exact component={RequireStudent(UserSubsctiptions)} />
        <Route path={PATHS.USER_COURSE_DETAILS.URL} exact component={RequireStudent(UserCourseDetails)} />
        <Route path={PATHS.USER_COURSE_STUDY.URL} exact component={RequireStudent(UserCourseStudy)} />
        <Route path={PATHS.USER_PERFORMANCE.URL} exact component={RequireStudent(UserPerformance)} />
        <Route path={PATHS.USER_BANK_MCQS.URL} exact component={RequireStudent(UserMcqsBank)} />
        <Route path={PATHS.USER_BANK_ESSAY.URL} exact component={RequireStudent(UserEssaysBank)} />
        <Route path={PATHS.USER_BANK_TBS.URL} exact component={RequireStudent(UserTbsBank)} />
        <Route path={PATHS.USER_BANK_FILE.URL} exact component={RequireStudent(UserFileBank)} />
        <Route path={PATHS.USER_MOCK_EXAM.URL} exact component={RequireStudent(UserMockExam)} />
        <Route path={PATHS.USER_TEST_KNOWLEDGE_EXAM.URL} exact component={RequireStudent(UserExam)} />
        <Route path={PATHS.USER_FREQ_EXAM.URL} exact component={RequireStudent(UserFreqExam)} />
        <Route path={PATHS.USER_EXAM_LOG.URL} exact component={RequireStudent(UserExamsLog)} />
        <Route path={PATHS.USER_EXAM_CORRECTION.URL} exact component={RequireStudent(UserExamCorrection)} />
        <Route path={PATHS.USER_EXAM_RESUTLS.URL} exact component={RequireStudent(UserExamResutls)} />
        <Route path={PATHS.USER_NOTIFICATIONS.URL} exact component={RequireStudent(UserNotifications)} />

        {/* Invalid Routes */}
        <Route>
            <Route path="*" exact component={UserDashboard} />
            <Redirect from='*' to={PATHS.USER_DASHBOARD.URL} />
        </Route>

    </Switch>;
};

export default React.memo(StudentStack);

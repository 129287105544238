import React, { useEffect, useState } from 'react';
import { Segment, Container, Header, Image, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { HtmlEditor, TableContextMenu, TableResizing } from 'devextreme-react/ui/html-editor';
import McqsQuestionTemplate from '../Templates/McqsQuestionTemplate';
import TextQuestionTemplate from '../Templates/TextQuestionTemplate';
import McqsQuestionMockCMATemplate from '../Templates/McqsQuestionMockCMATemplate';
import { ErrorSegment } from '../../..';
import { Consts, Styles } from '../../../../res';
import iconImage from '../../../../res/images/logo.svg';
import { replaceFontFamily } from '../../../../helpers/general';

const { noExtraSpaces } = Styles;

const QuestionPage = (props) => {

    const { Q, onAnswerQuestion, loading, error, isMockExam, isViewOnly, user, onHideSomeAnswers, onExcludeOption } = props;
    const { t } = useTranslation();
    const [showScrollbar, setShowScrollbar] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        const checkForScrollbar = () => {
            const body = document.body;
            const hasScrollbar = body.scrollHeight > window.innerHeight;
            setShowScrollbar(hasScrollbar);
        };
        const handleScroll = () => (window.scrollY > 0) && setIsScrolling(true);
        checkForScrollbar();
        window.addEventListener('scroll', handleScroll);

        return () => {
            setIsScrolling(false);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [Q?.questionId, loading]);

    if (loading || !Q) return <Segment basic style={{ height: '40vh', ...noExtraSpaces }} loading={loading} />;
    if (error) return <ErrorSegment />;

    const renderMcqsTemplate = () => {
        if (Q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS) {
            if (isMockExam)
                return <McqsQuestionMockCMATemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isViewOnly={isViewOnly} onExcludeOption={onExcludeOption} />;
            else return <McqsQuestionTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isViewOnly={isViewOnly} onHideSomeAnswers={onHideSomeAnswers} onExcludeOption={onExcludeOption} />;
        }
        return null;
    };

    const isEssay = Q?.questionTypeId === Consts.QUESTIONS_TYPES.ESSAY;

    return (
        <Segment style={noExtraSpaces}>
            <Container style={{ width: '90vw', position: 'relative' }}>

                {showScrollbar && !isScrolling && <div
                    className='tw-text-xl tw-fixed tw-bg-SECONDARY_60 tw-bottom-10 tw-z-50 tw-right-5 tw-p-5 tw-text-white'>
                    {t("PageRequiresScrolling")}
                    <span className="tw-mx-3"><Icon link name='x' size='small' circular style={{ backgroundColor: 'white', color: 'black' }} onClick={() => setShowScrollbar(false)} /></span>
                </div>}

                <Segment basic>
                    {
                        isEssay ? <div className={`tw-grid tw-gap-5 tw-mb-10 md:tw-mb-0 ${!Q?.showFactOnly && "md:tw-grid-cols-2"}`}>
                            <div>
                                <HtmlEditor value={replaceFontFamily(Q.factContent.trim())} readOnly style={{ backgroundColor: '#ECECEC', minHeight: '35vh', height: '62vh' }} className='resizo' >
                                    <TableContextMenu enabled />
                                    <TableResizing enabled />
                                </HtmlEditor>
                            </div>

                            {!Q?.showFactOnly && <div>
                                <HtmlEditor value={replaceFontFamily(Q.questionText.trim())} readOnly style={{ backgroundColor: '#ECECEC' }} className='resizo' >
                                    <TableContextMenu enabled />
                                    <TableResizing enabled />
                                </HtmlEditor>

                                <TextQuestionTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isMockExam={isMockExam} isViewOnly={isViewOnly} />
                            </div>}
                        </div>

                            : <div>
                                <HtmlEditor value={replaceFontFamily(Q.questionText.trim())} readOnly style={{ backgroundColor: '#ECECEC' }} className='resizo' >
                                    <TableContextMenu enabled />
                                    <TableResizing enabled />
                                </HtmlEditor>
                                {renderMcqsTemplate()}
                            </div>
                    }
                </Segment>

                <div className='studentExamWatermark' style={{ top: '40%', left: '40%' }}>
                    <Image loading="lazy" size='medium' src={iconImage} />
                </div>

                <Header
                    as='h1'
                    content={`${user.firstName} ${user.lastName} [${user.email}] ${user.contactNumber}`}
                    className='studentExamWatermark'
                />

            </Container>

        </Segment>
    );
};

export default QuestionPage;
import React, { useState } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import '../../../res/css/modal-draggable.css';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import AllowCopyPaste from '../../Operantinals/AllowCopyPaste';
// import pdfFile from '../../../res/pdfs/Information.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.js`;

const options = {
    disableFontFace: true
};

const TBSAttachmentModal = ({ title, docUrl, open, closeModalAction, index, onClose = null,
    fileKey = null, bgColor = '#084f69', headerBgColor = '#043a4e', headerColor = '#fff',
    closeButtonBgColor = '#d5440b', closeButtonColor = '#fff'

}) => {
    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1);
    const [width, setWidth] = useState(200);
    const [height, setHeight] = useState(500);

    const onResize = (event, { node, size, handle }) => {
        setWidth(size.width);
        setHeight(size.height);
    };

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages);
    };

    const onDocumentLoadError = (error) => {
        console.log('prd', error);
    };

    const onDocumentSourceLoadError = (error) => {
        console.log('pdf', error);
    };

    if (!open)
        return null;

    return (
        <AllowCopyPaste>
            <Draggable handle="#handle" cancel={".zoom-bar, .react-resizable-handle"}>
                <Resizable height={height} width={width} onResize={onResize}>
                    <div style={{ position: 'absolute', top: '5%', left: '50%', border: 'solid 1px #ccc' }}>
                        <div id="handle" className='pdf-modal-header' style={{ backgroundColor: headerBgColor, color: headerColor }}>
                            <span id="ui-id-23" className="ui-dialog-title">{title}</span>
                            <button className="driver-dialog-titlebar-close"
                                style={{ backgroundColor: closeButtonBgColor, color: closeButtonColor }}
                                onClick={() => {
                                    if (onClose) {
                                        onClose(prev => {
                                            const temp = { ...prev };
                                            delete temp[fileKey];
                                            return { ...temp };
                                        });
                                    }
                                    else {
                                        closeModalAction(index);
                                    }
                                }}>
                                <span aria-label="Close" >
                                    <Icon link name='x' />
                                </span></button>
                        </div>
                        <div style={{ backgroundColor: bgColor }} className='zoom-bar' >
                            <Icon
                                style={{ margin: '5px' }}
                                name='search plus'
                                onClick={() => {
                                    setScale(scale + 0.1);
                                }}
                                color='grey'
                            />
                            <Input
                                style={{ margin: '5px' }}
                                type='number'
                                min={25} max={1000}
                                value={(Number(scale * 100)).toFixed(0)}
                                onChange={(e, { value }) => {
                                    setScale(value / 100);
                                }}
                            />
                            <Icon
                                style={{ margin: '5px' }}
                                name='search minus'
                                onClick={() => {
                                    setScale(scale - 0.1);
                                }}
                                color='grey'
                            />
                        </div>
                        <div className='box' style={{
                            overflow: 'scroll',
                            width: (width + 10) + 'px', height: height + 'px'
                        }}>
                            <Document
                                //file='https://learning-go.com/api/fs/doc/1690479059638_2022%20CPA%20(FAR.01.00)%20-TBS%20-%20%20Financial%20Accounting%20Basics%20(V3)%20(1).pdf' 
                                file={docUrl}
                                //file={pdfFile}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={onDocumentLoadError}
                                onSourceError={onDocumentSourceLoadError}
                                options={options}
                            >
                                {Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            scale={scale}
                                            onLoadSuccess={(page) => {
                                                if (index === 0) {
                                                    setWidth(page.width);
                                                    setHeight(page.height > 570 ? 570 : page.height);
                                                }
                                            }}
                                        />
                                    ),
                                )}
                            </Document>
                        </div>
                    </div>
                </Resizable>
            </Draggable>
        </AllowCopyPaste >
    );
};

export default withTranslation()(TBSAttachmentModal);